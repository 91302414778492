body {
  margin: 0;
  border-color: aliceblue;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -webkit-user-select: none;  /* Chrome, Safari, Opera */
  -khtml-user-select: none;   /* KHTML browsers (e.g. Konqueror) */
  -moz-user-select: none;     /* Old versions of Firefox */
  -ms-user-select: none;
  /* padding-bottom: env(safe-area-inset-bottom); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'PetitCochon';  /* Nommez la police comme vous le souhaitez */
    src: url('/assets/fonts/PetitCochon.otf') format('opentype'),  /* Path vers votre fichier .otf */
         url('/assets/fonts/PetitCochon.ttf') format('truetype');  /* Path vers votre fichier .ttf */
    font-weight: normal;
    font-style: normal;
}
